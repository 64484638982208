import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import api from "../Api";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";


const UserProfile = () => {
    const { token, setUserProfile } = useContext(UserContext);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [partnerEmail, setPartnerEmail] = useState("");
    const [partnerFirstName, setPartnerFirstName] = useState("");
    const [partnerNickname, setPartnerNickname] = useState("");
    const [partnerNumber, setPartnerNumber] = useState("");
    const [id, setId] = useState("");
    const [editing, setEditing] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        const getCurrentUser = async () => {
            api.getUser(token)
                .then(response => {
                    setEmail(response.data.email);
                    setFirstName(response.data.first_name);
                    setLastName(response.data.last_name);
                    setPartnerEmail(response.data.partner_email);
                    setPartnerFirstName(response.data.partner_first_name);
                    setPartnerNickname(response.data.partner_nickname);
                    setPartnerNumber(response.data.partner_number);
                    setId(response.data.id);
                })
                .catch(error => {
                    console.log(error);
                })
        };
        getCurrentUser();
    }, [token])

    const handleStartEditing = (e) => {
        e.preventDefault();
        setEditing(true);
    };

    const handleProfileUpdate = (e) => {
        e.preventDefault();
        api.updateUser(token, firstName, lastName, email, partnerEmail, partnerFirstName, partnerNickname, partnerNumber, id)
            .then(response => {
                setUserProfile({
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    partnerEmail: partnerEmail,
                    partnerFirstName: partnerFirstName,
                    partnerNickname: partnerNickname,
                    partnerNumber: partnerNumber,
                    id: id
                });
                setEditing(false);
                setSuccessMessage(response.data.detail);
            })
            .catch(error => {
                setErrorMessage(error.response.data.detail)
            })

        setEditing(false);
    };

    return (
        <div className="column">
            <form className="box">
                <h1 className="subtitle is-3 has-text-centered">User Profile</h1>
                <div className="field">
                    <label className="label">User ID</label>
                    <div className="control">
                        <input
                            placeholder="Enter First Name"
                            value={id}
                            className="input"
                            required
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">First Name</label>
                    <div className="control">
                        <input
                            placeholder="Enter First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="input"
                            required
                            disabled={!editing}
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Last Name</label>
                    <div className="control">
                        <input
                            placeholder="Enter Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className="input"
                            required
                            disabled={!editing}
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Email Address</label>
                    <div className="control">
                        <input
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="input"
                            required
                            disabled={!editing}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="columns">
                        <div className="column">
                            <label className="label">Partner First Name</label>
                            <div className="control">
                                <input
                                    placeholder="Enter Your Partner's First Name"
                                    value={partnerFirstName}
                                    onChange={(e) => setPartnerFirstName(e.target.value)}
                                    className="input"
                                    required
                                    disabled={!editing}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="field">
                    <div className="columns">
                        <div className="column">
                            <label className="label">Partner Nickname / Pet Name</label>
                            <div className="control">
                                <input
                                    placeholder="Enter a nickname / petname you call your partner (or leave it blank and we'll use their first name)"
                                    value={partnerNickname}
                                    onChange={(e) => setPartnerNickname(e.target.value)}
                                    className="input"
                                    required
                                    disabled={!editing}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="field">
                    <label className="label">Partner Email Address</label>
                    <div className="control">
                        <input
                            type="email"
                            placeholder="Enter you partner's email"
                            value={partnerEmail}
                            onChange={(e) => setPartnerEmail(e.target.value)}
                            className="input"
                            required
                            disabled={!editing}
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Partner Phone Number</label>
                    <div className="control">
                        <input
                            type="tel"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            placeholder="555-555-5555"
                            value={partnerNumber}
                            onChange={(e) => setPartnerNumber(e.target.value)}
                            className="input"
                            disabled={!editing}
                            required
                        />
                    </div>
                </div>
                <ErrorMessage message={errorMessage} />
                <SuccessMessage message={successMessage} />
                <br />
                {editing ? (
                    <button className="button is-warning" onClick={(e) => (handleProfileUpdate(e))}>
                        Save Profile
                    </button>
                ) : (
                    <button className="button is-primary" onClick={(e) => handleStartEditing(e)}>
                        Edit Profile
                    </button>
                )}
            </form>
        </div>
    );
};

export default UserProfile;