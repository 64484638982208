import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

const NotFoundPage = () => {
    const location = useLocation();

    useEffect(() => {
        // ReactGA.pageview(location.pathname + location.search);
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "NotFound Pageview" });
    }, [location]);
    return (
        <>
            <h1>How did you get here??</h1>
            <p>This page does not exist!!!</p>
        </>
    )
};

export default NotFoundPage;