import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage"
import { UserContext } from "../context/UserContext";
import { api } from "../Api";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const { setToken } = useContext(UserContext);

    const navigate = useNavigate();
    const loginRedirect = () => {
        const path = "/notes"
        navigate(path);
    };


    const submitLogin = async () => {

        api.loginGetToken(email, password)
            .then(response => {
                if (response.status !== 200) {
                    setErrorMessage(response.data.detail);
                } else {
                    setToken(response.data.access_token);
                    // navigate logged in user to notes page
                    loginRedirect();
                }
            })
            .catch(error => {
                // console.log(error)
                setErrorMessage(error.response.data.detail);
                // setErrorMessage("Unable to Login")
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitLogin();
    };

    const submitForgotPassword = async () => {
        if (!email) {
            setErrorMessage("Please Enter Email Above, then try again");
        } else {
            api.recoverPassword(email)
                .then(response => {
                    if (response.status !== 200) {
                        setErrorMessage(response.data.detail);
                    } else {
                        setErrorMessage("");
                        setSuccessMessage("Password reset instructions were successfully sent to your email");
                    };
                })
                .catch(error => {
                    console.log(error);
                    setErrorMessage(error.response.data.detail);
                });
        };
    };
    const handleForgotPassword = (e) => {
        e.preventDefault();
        submitForgotPassword();
    };

    return (
        <div className="column">
            <form className="box" onSubmit={handleSubmit}>
                <h1 className="title has-text-centered">Login</h1>
                <div className="field">
                    <label className="label">Email Address</label>
                    <div className="control">
                        <input
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="input"
                            required
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Password</label>
                    <div className="control">
                        <input
                            type="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input"
                            required
                        />
                    </div>
                </div>

                <ErrorMessage message={errorMessage} />
                <SuccessMessage message={successMessage} />
                <br />
                <section>
                    <div className="container has-text-centered">
                        <div>
                            <button className="button is-primary" type="submit">
                                Login
                            </button>
                        </div>
                        <div>
                            <button className="button is-small is-warning is-light my-2 is-outlined" onClick={(e) => handleForgotPassword(e)}>
                                Forgot Password?
                            </button>
                        </div>
                    </div>
                </section>
            </form>
        </div>
    )

};

export default Login;