import React, { useContext, useEffect } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";

import { UserContext } from "../context/UserContext";

const HomePage = () => {
    const { token } = useContext(UserContext);
    const { userProfile } = useContext(UserContext);

    const welcomeMessage = (userProfile) => {
        var message = ""
        if (userProfile) {
            // console.log(userProfile)
            message = `Welcome back ${userProfile.firstName}!`
        } else {
            message = "Relationship Memos"
        }
        return message
    };

    const location = useLocation();

    useEffect(() => {
        // ReactGA.pageview(location.pathname + location.search);
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Home Pageview" });
    }, [location]);


    return (
        <>
            <section className="hero is-halfheight">
                <div className="hero-body">
                    <div className="columns has-text-centered">
                        <div className="column is-half">
                            <p className="title">
                                {welcomeMessage(userProfile)}
                            </p>
                            {!token ? (
                                <>
                                    <div className="is-hidden-mobile">
                                        <p className="subtitle">Do you wish your partner felt more loved, connected, and appreciated by you? Do you want to strengthen your connection and your relationship?</p>

                                        <p className="subtitle">Relationship Memos helps you email or text a thoughtful note to your partner every day.
                                            With reminders, prompts, tips, and examples we help you craft a meaningful message that will make your partner smile.</p>

                                        <p className="subtitle">Relationship Memos is more than just a note. It is a regular habit that transforms your relationship with your partner. By writing a quick memo each day, you show your partner that you care, listen, and value them.</p>

                                    </div>
                                    <p className="subtitle is-hidden-desktop is-hidden-tablet">
                                        Show your partner that you care, listen, and value them...
                                    </p>
                                    <p className="subtitle is-hidden-desktop is-hidden-tablet">
                                        Relationship Memos helps you email or text a thoughtful note to your partner every day.
                                        With reminders, prompts, tips, and examples we help you craft a meaningful message that will make your partner smile.
                                    </p>
                                </>

                            ) : (null)}

                            {!token ? (
                                <>
                                    <a
                                        className="button is-medium is-primary ml-2 my-4 is-underlined"
                                        href="/signup"
                                    >
                                        <span className="icon">
                                            <i className="fas fa-user-alt"></i>
                                        </span>
                                        <span>Sign Up</span>
                                    </a>
                                    <a
                                        className="button is-medium is-info ml-2 my-4 is-underlined"
                                        href="/login"
                                    >
                                        <span className="icon">
                                            <i className="fas fa-sign-in-alt"></i>
                                        </span>
                                        <span>Log In</span>
                                    </a>
                                </>

                            ) : (
                                <a
                                    className="button is-medium is-info ml-2 my-4 is-underlined"
                                    href="/notes"
                                >
                                    <span className="icon">
                                        <i className="fas fa-file-alt"></i>
                                    </span>
                                    <span>Go to your Memos</span>
                                </a>
                            )}

                        </div>
                        <div className="column is-half">
                            <img src="/images/home_hero.jpeg" alt="" />
                        </div>

                    </div>
                </div>
            </section>

        </>

    )

};

export default HomePage;