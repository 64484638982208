import React from "react";

const RegisterCompleteCard = ({ customerEmail }) => {
    return (
        <section className="hero is-halfheight">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <h1 className="title pr-2">Welcome to Relationship Memos</h1>
                    <h2 className="subtitle pr-2">
                        Thank you for signing up for Relationship Memos.
                        Click below to get started writing!
                    </h2>
                    <a href="/notes" className="button is-medium is-primary is-underlined pr-2">Start Writing</a>
                </div>
            </div>
        </section>
    )
};

export default RegisterCompleteCard;