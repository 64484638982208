import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { api } from "../Api";
import { getThoughtStarter } from "../utils/ThoughtStarters"

const NoteModal = ({ active, handleModal, token, id, setErrorMessage, tutorialStep, setTutorialStep }) => {

    const [focus, setFocus] = useState({ section: "focus", content: "" });
    const [feeling, setFeeling] = useState({ section: "feeling", content: "" });
    const [question, setQuestion] = useState({ section: "question", content: "" });
    const [ideas, setIdeas] = useState({ section: "ideas", content: "" });
    const [gratitude, setGratitude] = useState({ section: "gratitude", content: "" });

    const focusPlaceholder = `-I have an important budget meeting this week that I need to finish preparing for.
- I am trying to learn how to write JavaScript so I can build cool websites`;
    const ideasPlaceholder = `- Alice has a band concert on tonight, I'll make sure I'm home from work by 4:30
- Let's try making our own ice cream this weekend, it sounds fun!
    `


    useEffect(() => {
        const getNote = async () => {

            api.getNoteByID(id, token)
                .then(response => {
                    if (response.status !== 200) {
                        setErrorMessage("Something Went Wrong. Couldn't Load Notes");
                    } else {

                        setFocus(response.data.note[0]);
                        setFeeling(response.data.note[1]);
                        setQuestion(response.data.note[2]);
                        setIdeas(response.data.note[3]);
                        setGratitude(response.data.note[4]);

                    };
                })
                .catch(error => { console.log(error) })
        };

        if (id) {
            getNote();
        };


    }, [id, token, setErrorMessage]);

    const cleanFormData = () => {
        setFocus({ section: "focus", content: "" });
        setFeeling({ section: "feeling", content: "" });
        setQuestion({ section: "question", content: "" });
        setIdeas({ section: "ideas", content: "" });
        setGratitude({ section: "gratitude", content: "" });
    };

    const handleUpdateNote = async (e) => {
        e.preventDefault();

        ReactGA.event("Update Note", {
            category: "Notes",
            action: "Update Note"
        });

        api.updateNote(id, { note: [focus, feeling, question, ideas, gratitude] }, token)
            .then(response => {
                if (response.status !== 200) {
                    setErrorMessage("Something went wrong when updating the note");
                } else {
                    // if successful clean form data and close the modal
                    cleanFormData();
                    handleModal();
                }
            })
            .catch(error => {
                setErrorMessage("Something went wrong when updating the note");
                console.log(error);
            });

    };

    const handleCreateNote = async (e) => {
        e.preventDefault();

        ReactGA.event("Create Note", {
            category: "Notes",
            action: "Create Note"
        });

        api.createNote({ note: [focus, feeling, question, ideas, gratitude] }, token)
            .then(response => {
                if (response.status !== 200) {
                    setErrorMessage("Something went wrong when updating the note");
                } else {
                    // if successful clean form data and close the modal
                    cleanFormData();
                    handleModal();
                }
            })
            .catch(error => {
                setErrorMessage("Something went wrong when updating the note");
                console.log(error);
            });

    };

    const handleThoughtStarter = (e, section) => {
        e.preventDefault();

        ReactGA.event("Thought Starter", {
            category: "Notes",
            action: `Thought Starter ${section}`,
        });

        const thoughtStarter = getThoughtStarter(section);

        const setters = {
            focus: setFocus,
            feeling: setFeeling,
            question: setQuestion,
            ideas: setIdeas,
            gratitude: setGratitude
        };
        setters[section]({ section: "question", content: thoughtStarter });
    }

    const handleModalClose = () => {
        cleanFormData();
        handleModal();
    };

    const completeTutorial = () => {
        setTutorialStep("Complete");
    };

    return (
        <div className={`modal ${active && "is-active"}`}>
            <div className="modal-background" onClick={handleModalClose}></div>
            <div className="modal-card">
                {tutorialStep === "CreateMemo" ? (
                    <div className="notification is-info has-text-centered">
                        <span>
                            Use the topics below to structure your memo (you can leave a section blank if you want to). Don't overthink what you write and use the lightbulbs to get an idea if you're stuck. Click Create to save your memo.
                        </span>
                        <div className="container has-text-centered mt-1">
                            <button className="button is-light" onClick={() => completeTutorial()}> Got It! I'm Set</button>
                        </div>
                    </div>
                ) : (null)}
                <header className="modal-card-head has-background-primary-light">
                    {tutorialStep !== "CreateMemo" ? (
                        <div className="columns is-vcentered is-mobile ">
                            <div className="column is-narrow">
                                <span className="icon">
                                    <i className="fas fa-lightbulb"></i>
                                </span>
                            </div>
                            <div className="column">
                                Click a lightbulb for ideas and thought starters if you have writers block. Leave a section blank if you don't want
                                to include it today
                            </div>
                        </div>
                    ) : (null)}

                </header>
                <section className="modal-card-body">
                    <form >
                        <div className="field">
                            <div className="columns is-vcentered is-mobile">
                                <div className="column">
                                    <label htmlFor="" className="label">Focus:  What am I focused and working towards</label>
                                </div>
                                <div className="column is-narrow">
                                    <button
                                        className="button is-small is-info is-light"
                                        onClick={(e) => handleThoughtStarter(e, "focus")}
                                    >
                                        <span className="icon">
                                            <i className="fas fa-lightbulb"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="control">
                                <textarea
                                    rows="5"
                                    className="textarea"
                                    placeholder={focusPlaceholder}
                                    value={focus.content}
                                    onChange={(e) => setFocus({ section: "focus", content: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <div className="columns is-vcentered is-mobile">
                                <div className="column">
                                    <label htmlFor="" className="label">Feeling:  What am I thinking about</label>
                                </div>
                                <div className="column is-narrow">
                                    <button
                                        className="button is-small is-info is-light"
                                        onClick={(e) => handleThoughtStarter(e, "feeling")}
                                    >
                                        <span className="icon">
                                            <i className="fas fa-lightbulb"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="control">
                                <textarea
                                    rows="5"
                                    className="textarea"
                                    placeholder="Tell your partner how you're feeling, what you're thinking about, what you're excited for, or what's worrying you"
                                    value={feeling.content}
                                    onChange={(e) => setFeeling({ section: "feeling", content: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <div className="columns is-vcentered is-mobile">
                                <div className="column">
                                    <label htmlFor="" className="label">Question:  What's something I want to ask my partner?</label>
                                </div>
                                <div className="column is-narrow">
                                    <button
                                        className="button is-small is-info is-light"
                                        onClick={(e) => handleThoughtStarter(e, "question")}
                                    >
                                        <span className="icon">
                                            <i className="fas fa-lightbulb"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <div className="control">
                                <textarea
                                    rows="5"
                                    className="textarea"
                                    placeholder="Is there anything you want to accomplish this weekend? || What's your favorite thing we've done together?"
                                    value={question.content}
                                    onChange={(e) => setQuestion({ section: "question", content: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <div className="columns is-vcentered is-mobile">
                                <div className="column">
                                    <label htmlFor="" className="label">Ideas:  Ideas I have, things I want to mention</label>
                                </div>
                                <div className="column is-narrow">
                                    <button
                                        className="button is-small is-info is-light"
                                        onClick={(e) => handleThoughtStarter(e, "ideas")}
                                    >
                                        <span className="icon">
                                            <i className="fas fa-lightbulb"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="control">
                                <textarea
                                    rows="5"
                                    className="textarea"
                                    placeholder={ideasPlaceholder}
                                    onChange={(e) => setIdeas({ section: "ideas", content: e.target.value })}
                                    value={ideas.content}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <div className="columns is-vcentered is-mobile">
                                <div className="column">
                                    <label htmlFor="" className="label">Gratitude: What am I grateful for</label>
                                </div>
                                <div className="column is-narrow">
                                    <button
                                        className="button is-small is-info is-light"
                                        onClick={(e) => handleThoughtStarter(e, "gratitude")}
                                    >
                                        <span className="icon">
                                            <i className="fas fa-lightbulb"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="control">
                                <textarea
                                    rows="5"
                                    className="textarea"
                                    placeholder="Tell your partner what you are grateful for"
                                    value={gratitude.content}
                                    onChange={(e) => setGratitude({ section: "gratitude", content: e.target.value })}
                                />
                            </div>
                        </div>
                    </form>
                </section>
                <footer className="modal-card-foot has-background-primary-light">
                    {id ? (<button className="button is-info" onClick={handleUpdateNote}>Update</button>) :
                        (<button className="button is-primary" onClick={handleCreateNote}>Create</button>)}
                    <button className="button" onClick={handleModalClose}>Cancel</button>
                </footer>
            </div>
        </div>
    )
};

export default NoteModal;
