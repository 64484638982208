// all routing logic for out app
import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from "./pages/HomePage";
import NotesPage from "./pages/NotesPage";
import NotFoundPage from "./pages/NotFoundPage";
import RegisterPage from "./pages/RegisterPage";
import PrivateRoutes from "./components/PrivateRoutes";
import LoginPage from "./pages/LoginPage";
import RecoveryPasswordPage from "./pages/RecoverPasswordPage";
import ProfilePage from "./pages/ProfilePage";
import AboutPage from "./pages/AboutPage";
import TandCPage from "./pages/TermsAndConditions";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";


const AppRoutes = () => {


    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/signup" element={<RegisterPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/recover-password" element={<RecoveryPasswordPage />} />
                <Route path="/about" element={<AboutPage />} />
                {/* Error Route */}
                <Route path="*" element={<NotFoundPage />} />
                {/* Protected Routes... Must be authenticated */}
                <Route element={<PrivateRoutes />}>
                    <Route path="/notes" element={<NotesPage />} />
                </Route>
                <Route element={<PrivateRoutes />}>
                    <Route path="/profile" element={<ProfilePage />} />
                </Route>
                {/* Privacy and Service Terms */}
                <Route path="/terms" element={<TandCPage />} />
                <Route path="/privacy" element={<PrivacyPolicyPage />} />
            </Routes>
        </Router>
    )


};

export default AppRoutes;