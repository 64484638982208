const focusQuestions = [
    "What are some of your short-term and long-term goals?",
    "What are some of the biggest challenges or obstacles you are facing right now?",
    "What are some of the things that motivate or inspire you?",
    "How do you measure your progress or success in your endeavors?",
    "What are some of the skills or habits you are working on improving or developing?",
    "How do you balance your work and personal life?",
    "What are some of the things that bring you joy or satisfaction in your life?"
];

const feelingQuestions = [
    "What are some of the things that are worrying you or making you anxious right now?",
    "What is currently a source of uncertainty or unpredictability in your life?",
    "What are some of the things that you are looking forward to or hoping for in the near future?",
    "How do you celebrate your achievements or milestones?",
    "What are some of the things that you are grateful for or appreciate in your life?",
    "How do you show your affection or appreciation to others, especially those who are important to you?",
    "What are some of the things that you enjoy doing or learning about in your spare time?"
]

const partnerQuestions = [
    "If you could swap bodies with any celebrity for a day, who would it be and why ?",
    "What are you excited about right now?",
    "What is the most embarrassing thing you have ever done on a date ?",
    "If you had to choose between being a superhero or a villain, which one would you pick and what would your name and power be ?",
    "What is the weirdest thing you have ever eaten or would like to try?",
    "If you won the lottery, what is the first thing you would buy or do with the money ?",
    "What is something you have always wanted to learn but never got around to ?",
    "If you could travel back in time, what era or event would you like to witness or change ?"
];

const ideasQuestions = [
    "What is happening this week that you don't want to forget to mention to your partner?",
    "What is a fun idea you have for this weekend that you want to suggest to your partner?",
    "What new million dollar business idea do you have?",
    "What something you've noticed in you life recently?",
    "What is something new you want to try making for dinner this week?"
]

const gratitudeIdeas = [
    "You are the reason I wake up every morning with a smile on my face. Thank you for making my life so beautiful and meaningful.",
    "I am so grateful for all the adventures we have shared and all the memories we have made. You are my favorite travel buddy and my favorite person.",
    "You are the best gift I have ever received. Thank you for choosing me and loving me unconditionally.",
    "I am so thankful for all the support and encouragement you have given me. You are my biggest fan and my greatest inspiration.",
    "I am grateful for this day, for my health, and for all the blessing I have in my life",
    "You are the most amazing mother/father to our children. Thank you for raising them with so much love and care.",
    "You are the most incredible lover I have ever had. Thank you for making me feel so good and so happy."
]

const thoughtStarters = {
    focus: focusQuestions,
    feeling: feelingQuestions,
    question: partnerQuestions,
    ideas: ideasQuestions,
    gratitude: gratitudeIdeas,
};

export const getThoughtStarter = (section) => {
    const startersList = thoughtStarters[section];

    const idx = Math.floor(Math.random() * startersList.length);
    return startersList[idx];
};