import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";
import ResetPassword from "../components/RecoverPassword";

const RecoveryPasswordPage = () => {
    const location = useLocation();
    useEffect(() => {
        // ReactGA.pageview(location.pathname + location.search);
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Recover Password Pageview" });
    }, [location]);

    const queryParams = new URLSearchParams(location.search)
    const recover_token = queryParams.get('token')

    return (
        <>
            <div className="columns my-3">
                <div className="column" />
                <div className="column is-three-quarters">
                    <ResetPassword recover_token={recover_token} />
                </div>
                <div className="column" />
            </div>
        </>
    )
};

export default RecoveryPasswordPage;
