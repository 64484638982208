import axios from 'axios';

const env = process.env.REACT_APP_ENV
console.log(`Running app in ENV: ${env}`)

let apiUrl = '';

if (env === 'production') {
    apiUrl = 'https://api.relationshipmemos.com';

} else if (env === 'stage') {
    apiUrl = 'https://api.localhost';
} else {
    apiUrl = 'http://localhost:8000';
}

export const api = {
    async loginGetToken(email, password) {
        return axios.post(`${apiUrl}/api/token`, `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`, { headers: { "Content-Type": "application/x-www-form-urlencoded" }, })
    },
    async getUser(token) {
        return axios.get(`${apiUrl}/api/users/me`, { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, } })
    },
    async recoverPassword(email) {
        return axios.post(`${apiUrl}/api/recover-password/${email}`, "", { headers: { "Content-Type": "application/json" } })
    },
    async resetPassword(token, newPassword) {
        console.log(token)
        console.log(newPassword)
        const reset = {
            token: token,
            new_password: newPassword
        }
        return axios.post(`${apiUrl}/api/reset-password`, reset, { headers: { "Content-Type": "application/json" } })
    },
    async registerUser(firstName, lastName, email, partnerEmail, partnerFirstName, partnerNickname, partnerNumber, password) {
        const user = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            partner_email: partnerEmail,
            partner_first_name: partnerFirstName,
            partner_nickname: partnerNickname,
            partner_number: partnerNumber,
            hashed_password: password
        }
        return axios.post(`${apiUrl}/api/users`, user, { headers: { "Content-Type": "application/json", } })
    },
    async updateUser(token, firstName, lastName, email, partnerEmail, partnerFirstName, partnerNickname, partnerNumber, id) {
        const user = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            partner_email: partnerEmail,
            partner_first_name: partnerFirstName,
            partner_nickname: partnerNickname,
            partner_number: partnerNumber,
            id: id
        }
        return axios.post(`${apiUrl}/api/users/me`, user, { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token } })
    },
    async getNoteByID(id, token) {
        return axios.get(`${apiUrl}/api/notes/${id}`, { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token } })
    },
    async getNotes(token) {
        return axios.get(`${apiUrl}/api/notes`, { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token } })
    },
    async createNote(note, token) {
        return axios.post(`${apiUrl}/api/notes`, note, { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token } })
    },
    async updateNote(id, note, token) {
        return axios.put(`${apiUrl}/api/notes/${id}`, note, { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token } })
    },
    async markNoteSent(id, token) {
        return axios.put(`${apiUrl}/api/notes/mark-sent/${id}`, "", { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token } })
    },
    async deleteNote(id, token) {
        return axios.delete(`${apiUrl}/api/notes/${id}`, { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token } })
    },
    async sendNote(id, token) {
        return axios.post(`${apiUrl}/api/send/${id}`, "", { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token } })
    },
    async apiRoot() {
        return axios.get(`${apiUrl}/api`, { headers: { "Content-Type": "application/json", } })
    },
    async sendIntroNote(token) {
        return axios.post(`${apiUrl}/api/user/send-intro`, "", { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token } })
    },
    async createCheckoutSession(email) {
        return axios.post(`${apiUrl}/api/create-checkout-session?email=${email}`, "", { headers: { "Content-Type": "application/json" } })
    },
    async getCheckoutStatus(sessionId) {
        return axios.get(`${apiUrl}/api/checkout-status?session_id=${sessionId}`, "", { headers: { "Content-Type": "application/json" } })
    }
};

export default api;