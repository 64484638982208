import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

import Login from "../components/Login";

const LoginPage = () => {
    const location = useLocation();

    useEffect(() => {
        // ReactGA.pageview(location.pathname + location.search);
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Login Pageview" });
    }, [location]);

    return (
        <>
            <div className="columns my-3">
                <div className="column" />
                <div className="column is-three-quarters">
                    <Login />
                </div>
                <div className="column" />
            </div>

        </>

    )

};

export default LoginPage;