import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

// import {
//     EmbeddedCheckoutProvider,
//     EmbeddedCheckout
// } from '@stripe/react-stripe-js';
// import { loadStripe } from "@stripe/stripe-js";

// import api from '../Api';
// import { UserContext } from "../context/UserContext";

// import { UserContext } from "../context/UserContext";

import Register from "../components/Register";
import RegisterCompleteCard from "../components/RegisterComplete";

// if (process.env.REACT_APP_ENV === 'production') {
//     // let key = 'pk_live_51OIfA6ENxfr6tbFwu3UzGvob53wYM9fS72J5mgsjt9C9J3DGTu9kw0l2T6AYnOe8qspgq8rbx9z8ijdMKC8VERvt00v0vjBDKf';
//     const stripePromise = loadStripe('pk_live_51OIfA6ENxfr6tbFwu3UzGvob53wYM9fS72J5mgsjt9C9J3DGTu9kw0l2T6AYnOe8qspgq8rbx9z8ijdMKC8VERvt00v0vjBDKf')
// } else {
//     // let key = 'pk_test_51OIfA6ENxfr6tbFw4N5EF5uBXTz9l7sPP2s7tLfk074vJKs2ir2Pc92Z04VCiPgTmNWkpPmUgtUqvYn3iOe85Rma007fXv8XG8';
//     const stripePromise = loadStripe('pk_test_51OIfA6ENxfr6tbFw4N5EF5uBXTz9l7sPP2s7tLfk074vJKs2ir2Pc92Z04VCiPgTmNWkpPmUgtUqvYn3iOe85Rma007fXv8XG8')
// };

// const stripePromise = loadStripe('pk_test_51OIfA6ENxfr6tbFw4N5EF5uBXTz9l7sPP2s7tLfk074vJKs2ir2Pc92Z04VCiPgTmNWkpPmUgtUqvYn3iOe85Rma007fXv8XG8')
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE)

const RegisterPage = () => {
    const progressLookup = { account: 10, subscribe: 50, complete: 100 }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    const location = useLocation();
    // const [clientSecret, setClientSecret] = useState("");

    const [registerStage, setRegisterStage] = useState("account")

    const [customerEmail, setCustomerEmail] = useState('');

    // const { setToken } = useContext(UserContext);


    // use effect will handle checking if checkout session has returned complete
    // useEffect(() => {
    //     // console.log("Session ID Logger");
    //     // console.log(sessionId);
    //     if (sessionId) {
    //         api.getCheckoutStatus(sessionId)
    //             .then(response => {
    //                 setCustomerEmail(response.data.customer_email);

    //                 if (response.data.status === "complete") {
    //                     setRegisterStage("complete");
    //                 }
    //             })
    //             .catch(error => {
    //                 console.log(error)
    //             });
    //     };
    // }, [sessionId]);

    // useEffect(() => {
    //     // console.log("setting token");
    //     // console.log(registerStage);
    //     if (registerStage === 'complete') {
    //         ReactGA.event("Subscription Complete", {
    //             category: "Sign up",
    //             action: "Submitted Sign Up"
    //         });

    //         setToken(localStorage.getItem('registerTempToken'));
    //         // clean up local storage
    //         localStorage.removeItem('registerTempToken');
    //     };

    // }, [registerStage, setToken]);

    // useEffect(() => {

    //     if (customerEmail) {
    //         api.createCheckoutSession(customerEmail)
    //             .then(response => {
    //                 if (response.status === 200) {
    //                     setClientSecret(response.data.client_secret);
    //                 } else {
    //                     console.log(response)
    //                 }
    //             })
    //             .catch(error => {
    //                 console.log(error)
    //             });
    //     }

    // }, [customerEmail])

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Sign up Pageview" });
    }, [location]);

    return (
        <>
            <div className="columns my-3">
                <div className="column" />
                <div className="column is-three-quarters">
                    <div className="columns my-0 is-mobile mx-1">
                        <div className="column">Account Set Up</div>
                        {/* <div className="column has-text-centered">Subscribe</div> */}
                        <div className="column"></div>
                        <div className="column has-text-right">Start Writing</div>
                    </div>
                    <progress
                        className="progress is-success mx-1"
                        value={progressLookup[registerStage]}
                        max={100}
                    />
                    {registerStage === 'account' && !sessionId ? (<Register setStage={setRegisterStage} setCustomerEmail={setCustomerEmail} />) : (null)}

                    {/* {clientSecret && registerStage === "subscribe" ? (
                        <EmbeddedCheckoutProvider
                            stripe={stripePromise}
                            options={{ clientSecret }}
                        >
                            <EmbeddedCheckout />
                        </EmbeddedCheckoutProvider>
                    ) : (null)} */}
                    {registerStage === 'complete' ? (<RegisterCompleteCard customerEmail={customerEmail} />) : (null)}

                </div>
                <div className="column" />
            </div>

        </>

    )

};

export default RegisterPage;