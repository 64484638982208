import React, { useContext, useState, useEffect } from "react";

import { getBrowser, getDeviceType, getDisplayMode } from './utils/DeviceInfo.js'

import { UserContext } from "./context/UserContext";

import AppRoutes from "./AppRoutes";


const App = () => {
  const { token, setToken } = useContext(UserContext);
  const [isNavActive, setIsNavActive] = useState(false);
  const [device, setDevice] = useState("");
  const [browser, setBrowser] = useState("");
  const [installPromptEvent, setInstallPromptEvent] = useState(null);
  const [installEligibility, setInstallEligibility] = useState(true);
  const [showInstallBanner, setShowInstallBanner] = useState(false);

  const handleLogOut = () => {
    setToken("");
    localStorage.setItem("noteADayToken", "")
  };


  // Last Use this to check if user already has the app before displaying the install prompt
  // https://petelepage.com/blog/2019/07/is-my-pwa-installed/#:~:text=First%2C%20you%20can%20use%20the,CSS%20display%20mode%20is%20standalone%20.


  // not technically sure if this needs to be in a use effect??
  // but shouldn't be too costly if it re-runs unnecessarily
  useEffect(() => {
    const eligibleBrowsers = ['Chrome', 'Edge', 'Safari', 'Firefox'];
    setDevice(getDeviceType());
    setBrowser(getBrowser());

    if (getDisplayMode() === 'browser') {
      if (installPromptEvent) {
        // app is installable... Browser fired the beforeinstallpromopt event
        setInstallEligibility(true);
      } else if (eligibleBrowsers.includes(browser)) {
        // consider app as installable and show install button
        setInstallEligibility(true);
      } else {
        setInstallEligibility(false);
      }
    } else {
      setInstallEligibility(false);
    }


  }, [installPromptEvent, browser])

  const buildInstallBanner = (device, browser) => {
    if (['iPhone', 'iPad'].includes(device) && browser === "Safari") {
      // return ("This is a apple device in Safari... Click share below and choose add to homepage")
      return (
        <>
          <ol >
            <li>
              Click on the share menu below.
              <img
                src="./images/safari-share-icon.png"
                alt="Safari Share Icon"
                style={{ width: 24, height: 24, marginTop: 10, marginBottom: 0 }}
              />
            </li>
            <li> Scroll down and select Add to Home Page</li>
          </ol >
        </>
      )
    }
    if (device === 'Desktop' && browser === "Safari") {
      return ("Click File >> Add to Dock to install the Relationship Memos App on your device")
    }
    if (browser === "Firefox") {
      return ("This is using Firefox... Please install the firefox PWA extention to install the app, or use a different browser")
    } else {
      return (`Browser: ${browser} || Device: ${device} ||`)
    }

  };

  const handleInstallPrompt = () => {

    if (installPromptEvent) {
      installPromptEvent.prompt();
      installPromptEvent.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          console.log("user accepted install");
        } else {
          console.log("user dismissed the install prompt");
        }
      });
    };

    if (!installPromptEvent && installEligibility) {
      // app can be installed but browser does not supprt any install banner natively
      setShowInstallBanner(true);
    }

  };


  let deferredPrompt;
  window.addEventListener('beforeinstallprompt', event => {

    event.preventDefault();

    deferredPrompt = event;

    // store install prompt event for use later
    setInstallPromptEvent(deferredPrompt);
  });

  // const handleSMSHook = () => {
  //   sms://1+${partnerNumberClean}&body=${smsBody}
  // };

  return (
    <>
      <nav className="navbar has-shadow">
        <div className="navbar-brand" style={{ height: 90 }}>
          {/* <a href="/home" className="navbar-item" style={{ width: 100 }}> */}
          <img className="ml-2 my-2" src="./images/logo.jpeg" style={{ width: 75, height: 75 }} alt="Relationship Memos Logo" />
          {/* <p className="ml-2 is-size-3">Relationship Memos</p> */}
          {/* </a> */}
          <div>
            {installEligibility && (<button className="button my-5 mx-3 is-primary" onClick={() => handleInstallPrompt()}>Get The App</button>)}
          </div>
          <button
            className={`navbar-burger burger ${isNavActive ? 'is-active' : ''}`}
            aria-label="menu"
            aria-expanded="false"
            data-target='navBarMenu'
            onClick={() => {
              setIsNavActive(!isNavActive)
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>
        <div className={`navbar-menu ${isNavActive ? 'is-active' : ''}`} id="navBarMenu">
          <div className="navbar-start ml-3">
            <a href="/home" className="navbar-item is-size-5 has-text-weight-medium">Home</a>
            <a href="/about" className="navbar-item is-size-5 has-text-weight-medium">About</a>
            {!token && (<a href="/signup" className="navbar-item is-size-5 has-text-weight-medium">Signup</a>)}
            {token && (<a href="/notes" className="navbar-item is-size-5 has-text-weight-medium">Memos</a>)}
            {/* <a href="sms://1+9522702527&body=testtesttest" className="button">SMS Hook</a> */}
          </div>
          <div className="navbar-end">
            {token ? (
              <>
                <a href="/" className="button is-danger mr-2 my-3 ml-3" onClick={handleLogOut}>
                  <span className="icon is-responsive">
                    <i className="fas fa-sign-out-alt"></i>
                  </span>
                </a>

                <a href="/profile" className="button is-info mr-2 my-3 ml-1">
                  <span className="icon is-responsivemedium">
                    <i className="fas fa-user-circle"></i>
                  </span>
                </a>
              </>
            ) : (
              <a href="/login" className="button is-info mr-3 my-3 ml-3">
                <span className="icon">
                  <i className="fas fa-sign-in-alt"></i>
                </span>
                <span>Log in</span>
              </a>
            )}
          </div>
        </div>
      </nav >
      {showInstallBanner && (
        <div className="notification is-primary">
          <button className="delete" onClick={() => setShowInstallBanner(false)}></button>
          {buildInstallBanner(device, browser)}
        </div>
      )}
      <AppRoutes />
      <footer className="footer has-background-white">
        <div className="container has-text-centered">
          <a className="button mr-2 ml-2 has-background-light" href="mailto:support@relationshipmemos.com?subject=Relationship Memos Support Request">Get Help</a>
          <a className="button mr-2 ml-2 has-background-light" href="mailto:support@relationshipmemos.com?subject=Relationship Memos Feedback">Give Us Feedback</a>
        </div>
      </footer>
    </>

  )

};

export default App;
