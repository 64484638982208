import React from 'react';
import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "bulma/css/bulma.min.css";

import App from './App';

import { UserProvider } from './context/UserContext';

const appEnv = process.env.REACT_APP_ENV
console.log(process.env.NODE_ENV)
if (appEnv !== "dev") {
  // intialize react google analytics
  const gaTrackingId = "G-J9JHQ84H18";

  ReactGA.initialize(gaTrackingId);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <App />
  </UserProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
// serviceWorkerRegistration.unregister();
if ('serviceWorker' in navigator) {
  console.log("trying to register service worker");
  navigator.serviceWorker.register("./serviceworker.js");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
