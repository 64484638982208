import React from "react";

const PrivacyPolicyPage = () => {
    return (
        <>
            <h1>Privacy Policy for Relationship Memos</h1>

            <p>Relationship Memos ("we," "us," or "our") is committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, and disclose information when you use our SaaS application, Relationship Memos (the "Service").</p>

            <h2>Information Collection and Use:</h2>
            <p>
                Information You Provide: When you use Relationship Memos, we may collect personal information, including but not limited to your name, email address, and any content you create within the app (such as notes and messages).

                Automatically Collected Information: We may also collect certain information automatically, such as your IP address, browser type, operating system, and usage data to improve the Service and user experience.
            </p>

            <h2>Use of Information:</h2>
            <p>
                Service Provision: We use the collected information to provide, maintain, and improve the Service, including personalizing your experience and providing support.

                Third-Party Service Providers: We may share information with third-party service providers (e.g., payment processors, email service providers) to perform services on our behalf, but only to the extent necessary for the operation of the Service.

                Legal Compliance: We may disclose information if required to do so by law or in response to valid requests by public authorities.
            </p>

            <h2>Data Security:</h2>
            <p>
                We implement reasonable security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information. Additionally, we store and retain no
                payment card or other sensitive payments data with in the service.
            </p>

            <h2>User Control:</h2>
            <p>
                Account Deletion: You have the right to delete your account and request the removal of all personal data from our Service. To do so, please contact us at support@relationshipmemos.com.

                Opt-Out: You can opt-out of receiving non-essential communications from us by following the instructions included in such communications.
            </p>

            <h2>Third-Party Sharing:</h2>
            <p>
                We agree to share information with third parties only to the extent necessary for providing the Service (e.g., processing payments, sending emails). We will not share your information with third parties outside of AlxMyr LLC. for non-necessary or marketing purposes.
            </p>

            <h2>Changes to Privacy Policy:</h2>
            <p>
                We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective upon posting the revised Privacy Policy on the Relationship Memos website.
            </p>

            <h2>Contact Us:</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at support@relationshipmemos.com.

                By using Relationship Memos, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.
            </p>

        </>
    )
};

export default PrivacyPolicyPage;