import React, { useContext, useState } from "react";
import ErrorMessage from "./ErrorMessage";
import { api } from "../Api";
import ReactGA from "react-ga4";

import { UserContext } from "../context/UserContext";

const Register = ({ setStage, setCustomerEmail }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [partnerEmail, setPartnerEmail] = useState("");
    const [partnerFirstName, setPartnerFirstName] = useState("");
    const [partnerNickname, setPartnerNickname] = useState("");
    const [partnerNumber, setPartnerNumber] = useState("");
    const [sendIntro, setSendIntro] = useState(true);

    const [errorMessage, setErrorMessage] = useState("");
    const { setToken } = useContext(UserContext);

    const submitRegistration = async () => {
        api.registerUser(
            firstName,
            lastName,
            email,
            partnerEmail,
            partnerFirstName,
            partnerNickname,
            partnerNumber,
            password)
            .then(response => {
                if (response.status !== 200) {
                    setErrorMessage(response.data.detail);
                } else {
                    ReactGA.event("Sign up Success", {
                        category: "Sign up",
                        action: "Submitted Sign Up"
                    });

                    if (sendIntro) {
                        console.log("Sending Intro Note");

                        ReactGA.event("Sent Intro Note", {
                            category: "Sign up",
                            action: "Submitted Sign Up"
                        });

                        api.sendIntroNote(response.data.access_token)
                            .then(response => {
                                console.log(response.status);
                            })
                            .catch(error => {
                                console.log(response.status);
                            });
                    };
                    // setStage("subscribe");
                    setStage("complete")
                    setCustomerEmail(email);
                    // localStorage.setItem("registerTempToken", response.data.access_token);
                    setToken(response.data.access_token);
                };
            })
            .catch(error => {

                ReactGA.event("Signup Error", {
                    category: "Sign up",
                    action: "Submitted Sign Up"
                });
                console.log(error)
                setErrorMessage(error.response.data.detail);
            })
            .finally(() => {
                return "complete"
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === confirmationPassword && password.length >= 8) {
            submitRegistration();
        } else {
            setErrorMessage(
                "Ensure that the passwords match and are at least 8 characters"
            );
        }
    };

    return (
        <div className="column">
            <form className="box" onSubmit={handleSubmit}>
                <h1 className="subtitle is-3 has-text-centered">Join us and connect with your partner today</h1>
                <div className="field">
                    <label className="label">First Name</label>
                    <div className="control">
                        <input
                            placeholder="Enter First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="input"
                            required
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Last Name</label>
                    <div className="control">
                        <input
                            placeholder="Enter Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className="input"
                            required
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Email Address</label>
                    <div className="control">
                        <input
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="input"
                            required
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="columns">
                        <div className="column">
                            <label className="label">Partner First Name</label>
                            <div className="control">
                                <input
                                    placeholder="Enter Your Partner's First Name"
                                    value={partnerFirstName}
                                    onChange={(e) => setPartnerFirstName(e.target.value)}
                                    className="input"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="field">
                    <div className="columns">
                        <div className="column">
                            <label className="label">Partner Nickname / Pet Name</label>
                            <div className="control">
                                <input
                                    placeholder="Enter a nickname / petname you call your partner (or leave it blank and we'll use their first name)"
                                    value={partnerNickname}
                                    onChange={(e) => setPartnerNickname(e.target.value)}
                                    className="input"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="field">
                    <label className="label">Partner Email Address</label>
                    <div className="control">
                        <input
                            type="email"
                            placeholder="Enter you partner's email"
                            value={partnerEmail}
                            onChange={(e) => setPartnerEmail(e.target.value)}
                            className="input"
                            required
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Partner Phone Number</label>
                    <div className="control">
                        <input
                            type="tel"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            placeholder="555-555-5555"
                            value={partnerNumber}
                            onChange={(e) => setPartnerNumber(e.target.value)}
                            className="input"
                            required
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Password</label>
                    <div className="control">
                        <input
                            type="password"
                            placeholder="Password should be at last 8 characters"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input"
                            required
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Confirm Password</label>
                    <div className="control">
                        <input
                            type="password"
                            placeholder="Enter password"
                            value={confirmationPassword}
                            onChange={(e) => setConfirmationPassword(e.target.value)}
                            className="input"
                            required
                        />
                    </div>
                </div>
                <ErrorMessage message={errorMessage} />
                <br />
                <div className="columns is-flex is-vcentered">
                    <div className="column">
                        <button className="button is-primary" type="submit">
                            Sign Up
                        </button>
                    </div>
                    <div className="column">
                        <label className="checkbox ml-2">
                            <input type="checkbox" onChange={(e) => setSendIntro(e.target.checked)} checked={sendIntro} />
                            Send intro note to my partner
                        </label>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Register;