import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

import Table from "../components/Table";

const NotesPage = () => {
    const location = useLocation();

    useEffect(() => {
        // ReactGA.pageview(location.pathname + location.search);
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Notes Pageview" });
    }, [location]);

    return (
        <>

            <div className="columns my-2">
                <div className="column" />
                <div className="column is-three-quarters">
                    <Table />
                </div>
                <div className="column" />
            </div>

        </>

    )

};

export default NotesPage;