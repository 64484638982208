import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";


const AboutPage = () => {
    const location = useLocation();

    useEffect(() => {
        // ReactGA.pageview(location.pathname + location.search);
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "About Pageview" });
    }, [location]);

    return (
        <section className="hero is-halfheight">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <h1 className="title is-underlined pr-2">Welcome to Relationship Memos</h1>
                    <h2 className="subtitle pr-2">
                        After a challenging and exceptionally busy period of life my wife and I noticed that we didn't feel as connected and close as we once did.
                        With a couple of young kids, a busy job, and the business of every day life I wanted a way to consistently and intentionally connect with my wife,
                        to let her know what I was focused on, thinking about, and working on.  I also wanted a way for us to stay more connected and aligned about what was going on
                        in our life.
                    </h2>
                    <h2 className="subtitle pr-2">
                        One day I stumbled on <a href="https://jdnoc.com/note/" className="link">this blog post by Jordan O'Connor</a> on Twitter that suggested writing a quick note to your wife daily.
                        I started doing it and over time developed a structed memo style note that I send my wife each morning. It gives her an update on what I'm working on, what I'm worried about, and ideas
                        that I want to remember to tell her. Its also an opportuntiy to check in with her on anything she needs from me, questions I have for her (sometimes serious, sometimes lighthearted, funny, or romantic).
                        Finally, its an opportunity for me to intentionally express some gratitude for her, for our kids, or for anything else in our life.
                    </h2>
                    <h2 className="subtitle pr-2">
                        After 8 or 9 months of writing this daily memo via email or text I wanted an easier way to do this morning ritual from my phone while getting our kids breakfast and out to the school bus in the morning.
                        So I started building <strong>Relationship Memos</strong>. Give it a try.... You, your significant other, and your relationship will thank you
                    </h2>
                    <a href="/signup" className="button is-medium is-primary is-underlined pr-2">Signup and Get Started</a>
                </div>
            </div>
        </section>
    )
};

export default AboutPage;