import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import ReactGA from "react-ga4";

import ErrorMessage from "./ErrorMessage";
import { UserContext } from "../context/UserContext";
import NoteModal from "./NoteModal";
import { api } from "../Api";

const Table = () => {
    const { token, userProfile } = useContext(UserContext);
    const [notes, setNotes] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [id, setId] = useState(null);
    const [welcomeNotification, setWelcomeNotification] = useState(false);
    const [tutorialStep, setTutorialStep] = useState("");

    const handleUpdate = async (id) => {
        setId(id);
        setActiveModal(true);
    };

    useEffect(() => {

        if (loaded) {
            if (notes.length === 0 && tutorialStep === "") {
                setWelcomeNotification(true);
            } else {
                setWelcomeNotification(false);
            };
        }

    }, [notes, tutorialStep, loaded]);

    const getNotes = async () => {
        api.getNotes(token)
            .then(response => {
                if (response.status !== 200) {
                    setErrorMessage("Something Went Wrong. Couldn't Load Notes");
                } else {
                    setNotes(response.data);
                };
            })
            .catch(error => {
                setErrorMessage("Something Went Wrong. Couldn't Load Notes");
                console.log(error)
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    const handleDelete = async (id) => {
        ReactGA.event("Delete Note", {
            category: "Notes",
            action: "Delete Note"
        });

        api.deleteNote(id, token)
            .then(response => {
                if (response.status !== 204) {
                    setErrorMessage("Failed to Delete Note")
                }
                setErrorMessage("")
            })
            .catch(error => {
                setErrorMessage("Failed to Delete Note")
                console.log(error)
            })
            .finally(() => { getNotes(); });
    };

    const handleSend = async (id) => {

        ReactGA.event("Send Note", {
            category: "Notes",
            action: "Send Note"
        });

        api.sendNote(id, token)
            .then(response => {
                if (response.status !== 201) {
                    setErrorMessage("Failed to Send Note")
                }
                setErrorMessage("")
            })
            .catch(error => {
                setErrorMessage("Failed to Send Note")
                console.log(error)
            })
            .finally(() => {
                getNotes();
            });

    };

    const handleSendSMS = (e, id) => {
        e.preventDefault();

        ReactGA.event("Send Note SMS", {
            category: "Notes",
            action: "Send Note"
        });

        api.getNoteByID(id, token)
            .then(response => {
                console.log(response);
                const note = response.data.note;
                const focusSection = `What I'm focused on / working towards right now:\n${note[0].content}\n\n`;
                const feelingSection = `How I'm feeling (what I'm excited about, what's bothering me, etc):\n${note[1].content}\n\n`;
                const questionSection = `Question(s) I have for you:\n${note[2].content}\n\n`;
                const ideaSection = `Ideas I have and things I'm thinking about:\n${note[3].content}\n\n`;
                const gratitudeSection = `Gratitude:\n${note[4].content}\n\n`;

                const smsBody = encodeURIComponent(`${focusSection}${feelingSection}${ideaSection}${questionSection}${gratitudeSection}`);
                const partnerNumberClean = userProfile.partnerNumber.replaceAll("-", "").replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "");

                // #TODO: fix double window.location.href (will prob cause bugs later)
                // const smsUrl = `sms://1+${partnerNumberClean}&body=${smsBody}`
                // window.location.href = smsUrl;

                api.markNoteSent(id, token)
                    .then(response => {
                        getNotes();
                        const smsUrl = `sms://1+${partnerNumberClean}&body=${smsBody}`
                        window.location.href = smsUrl;
                    })
                    .catch(error => {
                        setErrorMessage("Error Marking Note Sent");
                    });

            })
            .catch(error => {
                setErrorMessage("Failed to share note via SMS");
                console.log(error);
            });
    };



    useEffect(() => {
        getNotes();

        // eslint-disable-next-line
    }, []);

    const handleModal = () => {
        setActiveModal(!activeModal);
        getNotes();
        setId(null);
    };

    const tutorialStart = () => {
        setWelcomeNotification(false);
        setTutorialStep("MemoPage");
    };

    const tutorialAdvance = (step) => {
        setTutorialStep(step);
        if (step === "NewMemoButton") {

        }
        if (step === "CreateMemo") {
            setActiveModal(true);
        }
    };

    return (
        <>
            <NoteModal
                active={activeModal}
                handleModal={handleModal}
                token={token}
                id={id}
                setErrorMessage={setErrorMessage}
                tutorialStep={tutorialStep}
                setTutorialStep={setTutorialStep}
            />
            <div className={`notification is-info has-text-centered mx-2 ${welcomeNotification ? '' : 'is-hidden'}`}>
                <span>
                    Our structure and process will help you write a regular memo to your partner that will let them know what you're thinking about and help connect the two of you. Click below to start a quick tutorial
                </span>
                <div className="container has-text-centered mt-2">
                    <button className="button is-light" onClick={() => tutorialStart()}>Take The Tutorial</button>
                </div>
            </div >
            <div className={`notification is-info has-text-centered mx-2 ${tutorialStep === "MemoPage" ? '' : 'is-hidden'}`}>
                <span>
                    The memos page has you the memos you've written. Once you've written your first memos the actions section let's you
                    edit a draft, send a memo, or delete an old memo / draft.
                </span>
                <div className="container has-text-centered mt-2">
                    <button className="button is-light" onClick={() => tutorialAdvance("NewMemoButton")}>Next</button>
                </div>
            </div>
            <div className={`notification is-info has-text-centered mx-2 ${tutorialStep === "NewMemoButton" ? '' : 'is-hidden'}`}>
                <span>
                    Clicking new memo below will open the memo editor to start writing
                </span>
                <div className="container has-text-centered mt-2">
                    <button className="button is-light" onClick={() => tutorialAdvance("CreateMemo")}>Start My First Memo</button>
                </div>
            </div>
            <div className="container has-text-centered">
                {tutorialStep === "NewMemoButton" ? (
                    <button
                        className="button is-four-fifths mr-1 ml-1 mb-5 is-primary"
                        onClick={() => setActiveModal(true)}
                        disabled
                    >
                        <span className="icon">
                            <i className="fas fa-edit"></i>
                        </span>
                        <span>New Memo</span>
                    </button>
                ) : (
                    <button
                        className="button is-four-fifths mr-1 ml-1 mb-5 is-primary"
                        onClick={() => setActiveModal(true)}
                    >
                        <span className="icon">
                            <i className="fas fa-edit"></i>
                        </span>
                        <span>New Memo</span>
                    </button>
                )}

            </div>

            <ErrorMessage message={errorMessage} />
            {
                loaded && notes ? (
                    <table className="table is-fullwidth mx-1">
                        <thead>
                            <tr>
                                <th>Memo</th>
                                <th>Edited</th>
                                <th>Sent</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notes.map((note) => (
                                <tr key={note.id}>
                                    <td>{note.note[0].content.slice(0, 25)}</td>
                                    <td>{moment(note.date_last_updated).format("MMM Do YY")}</td>
                                    <td>
                                        <div className="container is-vcentered">
                                            <span className="icon mt-2">
                                                {note.note_sent ? (<i className="fas fa-check" style={{ color: "#35AC5E" }}></i>) : (null)}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="container has-text-centered">
                                            <button
                                                className="button my-1 mr-1 ml-1 is-primary"
                                                onClick={() => handleUpdate(note.id)}
                                            >
                                                <span className="icon is-medium">
                                                    <i className="fas fa-edit"></i>
                                                </span>
                                            </button>
                                            <button
                                                className="button my-1 mr-1 ml-1 is-danger"
                                                onClick={() => handleDelete(note.id)}
                                            >
                                                <span className="icon is-medium">
                                                    <i className="fas fa-trash-alt"></i>
                                                </span>
                                            </button>
                                            <button
                                                className="button my-1 mr-1 ml-1 is-warning"
                                                onClick={() => handleSend(note.id)}
                                            >
                                                <span className="icon is-medium">
                                                    <i className="fas fa-paper-plane"></i>
                                                </span>
                                            </button>
                                            {/* <button
                                                className="button my-1 mr-1 ml-1 is-info"
                                                onClick={() => handleSendSMS(note.id)}
                                            >
                                                <span className="icon is-medium">
                                                    <i className="fas fa-sms"></i>
                                                </span>
                                            </button> */}
                                            <a
                                                href="sms://"
                                                className="button my-1 mr-1 ml-1 is-info"
                                                onClick={(e) => handleSendSMS(e, note.id)}
                                            >
                                                <span className="icon is-medium">
                                                    <i className="fas fa-sms"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table >
                ) : <p>Loading</p>
            }
        </>
    )
}


export default Table;