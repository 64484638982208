import React from "react";

const TandCPage = () => {
    return (
        <>
            <p>Terms of Service for Relationship Memos </p>

            <p>
                Welcome to Relationship Memos! These Terms of Service ("Terms") govern your use of the Relationship Memos website, software, and services (collectively referred to as the "Service") provided by Relationship Memos ("we," "us," or "our").

                Acceptance of Terms: By accessing or using the Relationship Memos Service, you agree to be bound by these Terms and our Privacy Policy. If you do not agree with any part of these Terms, you may not use our Service.

                Description of Service: Relationship Memos is a SaaS application designed to assist husbands and boyfriends in composing daily notes for their significant others to nurture and enhance their relationships.
            </p>
            <p>
                User Responsibilities:
                a.You must be at least 18 years old to use the Service.
                b.You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account.
                c.You agree to use the Service in compliance with all applicable laws and regulations.
            </p >

            <p>User Content: Users are solely responsible for the content of the messages and notes they create using the Relationship Memos Service.We do not claim ownership of any content you generate through the Service.</p>
            <p>Privacy: We respect your privacy and handle your personal information in accordance with our Privacy Policy.By using Relationship Memos, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.</p>
            <p>Intellectual Property: Relationship Memos and its content, features, and functionality are owned by us and are protected by copyright, trademark, and other intellectual property laws.</p>
            <p>Prohibited Activities: You agree not to engage in any prohibited activities, including but not limited to:
                a.Violating any laws or regulations.
                b.Using the Service for any unlawful or unauthorized purpose.
                c.Attempting to interfere with the proper functioning of the Service.</p>
            <p>Termination: We reserve the right to terminate or suspend access to the Service immediately, without prior notice or liability, for any reason whatsoever, including breach of these Terms.</p>
            <p>Disclaimer of Warranties: The Service is provided on an "as is" and "as available" basis without warranties of any kind, whether express or implied.</p>
            <p>Refunds and Subscription Termination: No refunds will be offered for services already rendered and made reasonably available. Users/customers may cancel their subscription to Relationship Memos at any time by emailing support@relationshipmemos.com and requesting immediate cancellation
                and termination of any future or recurring charges.
            </p>
            <p>Limitation of Liability: We shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Service.</p>
            <p>Governing Law: These Terms shall be governed by and construed in accordance with the laws of The United States of America, without regard to its conflict of law provisions.</p>
            <p>Changes to Terms: We reserve the right to modify or replace these Terms at any time.It is your responsibility to review these Terms periodically for changes.</p>
            <p>Contact Us: If you have any questions or concerns about these Terms, please contact us at support @relationshipmemos.com.</p>
            <p>By using Relationship Memos, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>

        </>


    )
};

export default TandCPage;