import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";
import UserProfile from "../components/UserProfile";

const ProfilePage = () => {
    // const { userProfile } = useContext(UserContext);
    // const [profileItems, setProfileItems] = useState([])

    const location = useLocation();
    useEffect(() => {
        // ReactGA.pageview(location.pathname + location.search);
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Profile Pageview" });
    }, [location]);

    // const printProfile = (profile) => {
    //     let items = []
    //     if (profile) {
    //         Object.entries(profile).forEach(([key, value]) => {
    //             console.log(`Key is ${key} and Value is ${value}`);
    //             items.push(`${key} || ${value}`);
    //         });
    //     };
    //     setProfileItems(items);
    // };

    // useEffect(() => {
    //     console.log("Printing Profile");
    //     printProfile(userProfile);
    // }, [userProfile]);

    return (
        <>
            <div className="columns my-3">
                <div className="column"></div>
                <div className="column is-three-quarters">
                    <UserProfile />
                </div>
                <div className="column"></div>
            </div>
        </>
    )
};

export default ProfilePage;