import React, { useState } from "react";
import ReactGA from "react-ga4";
import api from '../Api';
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";


const ResetPassword = (props) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const submitPasswordReset = async () => {
        api.resetPassword(props.recover_token, password)
            .then(response => {
                if (response.status !== 200) {
                    setErrorMessage(response.data.detail)
                } else {
                    ReactGA.event("PWD Reset Success", {
                        category: "User Account",
                        action: "Reset Password"
                    });

                    setErrorMessage("");
                    setSuccessMessage(response.data.detail);
                }
            })
            .catch(error => {
                ReactGA.event("PWD Reset Failure", {
                    category: "User Account",
                    action: "Reset Password",
                });

                console.log(error)
                setErrorMessage("There was a problem updating the password")
            })
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // validate new password
        if (password === confirmPassword && password.length >= 8) {
            submitPasswordReset()
        } else {
            setErrorMessage("Ensure that the passwords match and are at least 8 characters")
        }

    };

    return (
        <div className="column">
            <form className="box" onSubmit={handleSubmit}>
                <h1 className="title has-text-centered">Reset Password</h1>
                <div className="field">
                    <label className="label">New Password</label>
                    <div className="control">
                        <input
                            type="password"
                            placeholder="Enter new password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input"
                            required
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Confirm New Password</label>
                    <div className="control">
                        <input
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="input"
                            required
                        />
                    </div>
                </div>

                <ErrorMessage message={errorMessage} />
                <SuccessMessage message={successMessage} />
                <br />
                <button className="button is-primary" type="submit">
                    Reset Password
                </button>
            </form>
        </div>
    )
};

export default ResetPassword;