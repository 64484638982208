import React, { createContext, useEffect, useState } from "react";
import { api } from "../Api";

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem("noteADayToken"));
    const [userProfile, setUserProfile] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            // console.log(token);
            api.getUser(token)
                .then(response => {
                    if (response.status !== 200 && token) {
                        console.log("no good, nulling token");
                        setToken("");
                        localStorage.setItem("noteADayToken", "")
                    } else {
                        // console.log("Context Logger")
                        // console.log(token);
                        setToken(token);
                        localStorage.setItem("noteADayToken", token);
                        // console.log(response.data)
                        // console.log(response.data.first_name)
                        setUserProfile({
                            email: response.data.email,
                            firstName: response.data.first_name,
                            lastName: response.data.last_name,
                            partnerEmail: response.data.partner_email,
                            partnerFirstName: response.data.partner_first_name,
                            partnerNickname: response.data.partner_nickname,
                            partnerNumber: response.data.partner_number,
                            id: response.data.id
                        })
                    };
                })
                .catch(error => {
                    // console.log(token);
                    if (token) {
                        setToken("");
                        localStorage.setItem("noteADayToken", "")
                    };

                })



        };
        fetchUser();
    }, [token,]);

    return (
        <UserContext.Provider value={{ token, setToken, userProfile, setUserProfile }}>
            {props.children}
        </UserContext.Provider>
    );
};